export function getStyleString(configuration) {

  const style = `

    #${configuration.id}:hover {
        background-color: ${configuration.hoverBackgroundColor};
        color: ${configuration.hoverTextColor};
      }
  `;

  return style;

}

function getStyleBlockId(configuration) {

  return `${configuration.id}-style`;

}

export function deleteStyleBlock(configuration) {

  const styleBlock = document.getElementById(getStyleBlockId(configuration));

  styleBlock.parentNode.removeChild(styleBlock);

}

export function addStyleBlock(configuration) {

  const styleBlockId = getStyleBlockId(configuration);

  document.head.insertAdjacentHTML('beforeend', `<style id="${styleBlockId}">${getStyleString(configuration)}</style>`);

}

export function updateStyleBlock(configuration) {

  deleteStyleBlock(configuration);

  addStyleBlock(configuration);

}

export function determineRedirectPathInputValue(clickHandler) {

  const lines = clickHandler.split('\n').filter(line => line.trim() !== '');

  // If lines are more than 3, user have modified the action. They will know the meaning of 'Custom action defined!'
  if (lines.length !== 3) {
    return null;
  }

  const redirectLineIndex = 1;

  const line = lines[redirectLineIndex];

  // If redirect line is default, page path is the redirect path
  // eslint-disable-next-line quotes
  if (line.indexOf(`window.open(window.location.href, '_blank');`) !== -1) {
    return window.location.href;
  }

  var path = line.split(' = ').pop().replace(';', '').replace(/'/g, '').trim();

  if (path) {
    return path;
  } else {
    return null;
  }

}

export function formCompleteURL(inputString) {
  // Check if the inputString is already a complete URL
  try {
    new URL(inputString);
    return inputString; // If it's a complete URL, return as is
  } catch (error) {
    return `${window.location.origin}${inputString}`;
  }
}