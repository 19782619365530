import { setChildren, setAttr } from 'https://cdn.venturz.co/lib/redom.es.js';

import Spinner from 'https://cdn.venturz.co/components/core/spinner/index.js';

import { createFunction } from '../utilities/language.js';
import { generateUUID, sendBeacon } from '../utilities/browser.js';

import * as buttonUtilities from '/programs/button/utilities.js';

import dependencyNames from '/programs/constants/dependency-names.js';

import {
  determineRedirectPathInputValue,
  formCompleteURL
} from './utilities.js';

export default class AuthenticatorButton {

  constructor(configuration, page) {

    this.page = page;
    this.configuration = configuration;
    this.container = null;
    this.button = null;
    this.buttonContents = [];
    this.spinner = new Spinner({ size: 'small' });

    this.disabled = true;
    this.userId = this.page.getUserId();

    setAttr(this.spinner.el, {
      style: {
        'margin': '0 auto'
      }
    });

  }

  addStyles() {

    const configuration = this.configuration;

    buttonUtilities.addStyleBlock(configuration);

  }

  activateLink() {

    this.disabled = false;
    this.container.addEventListener('click', this.handleButtonClick.bind(this));
    this.button.removeAttribute('style');

    setChildren(this.container, [this.button]);

  }

  async loadDependencies() {

    await this.page.getDependency(dependencyNames.API_INTERFACE);

  }

  tryHydrateLocalRedirectParameters() {

    this.page.deleteQueryParameters(['authorizationToken', 'userId', 'label']);

  }

  async hydrateQueryParameters() {

    const code = decodeURIComponent(this.page.url.searchParams.get('code'));
    const authenticationRedirection = decodeURIComponent(this.page.url.searchParams.get('auth-redirect'));
    const authData = JSON.parse(decodeURIComponent(this.page.url.searchParams.get('auth-data')));
    const state = JSON.parse(decodeURIComponent(this.page.url.searchParams.get('state')));

    this.tryHydrateLocalRedirectParameters();

    if (authenticationRedirection !== 'true') {
      return;
    }

    if (state.authButtonId !== this.configuration.id) {
      return;
    }
    sendBeacon('/batman-begins', { type: 'success/received', data: state });

    try {

      this.page.indicators.showFullPageSpinner();
      this.page.deleteQueryParameters(['code', 'auth-redirect', 'auth-data', 'state']);

      const authorization = await this.exchangeCodeForAuthorization(code);
      sendBeacon('/batman-begins', { type: 'success/authorized', data: state });
      const user = await this.activateAuthorization(authorization);
      if (authData.registered === true) {
        await this.page.userObserver.tryEmitSignupEvent(authData);
      }
      this.page.deleteQueryParameters(['__login_mode__', '__login_source__']);
      sendBeacon('/batman-begins', { type: 'success/authorization-active', data: state });
      await this.redirectToURL(authorization.token, user, state, authData);

    } catch (exception) {
      state.exception = exception;
      sendBeacon('/batman-begins', { type: 'success/authorization-failure', data: state });

    }

  }

  async initialize() {

    this.container = document.getElementById(this.configuration.id);
    this.button = this.container.querySelector('a.link-button-control');
    this.buttonContents = Array.from(this.button.children);

    await this.loadDependencies();

    this.addStyles();

    await this.hydrateQueryParameters();

    this.activateLink();

  }

  tryFormHref(href) {

    if (href.indexOf('http') === 0) {
      return href;
    }

    let _href = href;

    if (_href.indexOf('/') !== 0) {
      _href = `/${_href}`;
    }

    return window.location.origin + _href;

  }

  async exchangeCodeForAuthorization(code) {

    const apiInterface = this.page.getAPIInterface();

    const authorization = await apiInterface.authentication.getAuthorizationByAccessObjectCode(code);

    return authorization;

  }

  async activateAuthorization(authorization) {

    const apiInterface = this.page.getAPIInterface();

    await this.page.setAuthorization(authorization);
    await this.page.initializeEventSystem(authorization.token);

    const user = await apiInterface.user.tryConnectStartupUserAndObserver(authorization.userId, this.page.userObserver.getObserverId());

    await this.page.userObserver.setObserverId(user.observerId);

    await this.page.eventSystem.tryAssociateWithObserverId({
      authorizationToken: authorization.token,
      observerId: this.page.userObserver.getObserverId()
    });

    return user;

  }

  async redirectToURL(authorizationToken, user, state = {}, data) {

    const userId = user._id;
    let clickHandlerString = this.configuration.clickHandler;
    const url = determineRedirectPathInputValue(clickHandlerString);

    if (url !== null) {
      const completeURL = formCompleteURL(url);

      const button = document.getElementById(this.configuration.id).querySelector('a.link-button-control');
      const urlObject = new URL(completeURL);
      urlObject.searchParams.append('authorizationToken', authorizationToken);
      urlObject.searchParams.append('userId', userId);
      urlObject.searchParams.append('state', JSON.stringify(state));
      urlObject.searchParams.append('label', button.textContent);

      const urlReplacement = urlObject.toString();

      clickHandlerString = clickHandlerString
        .replace(url, urlReplacement); // if url is hard coded path, it needs to be replaced by same path with query parameters
      // .replace('window.location.href', `'${urlReplacement}'`); // COMMENTED as I forgot the scenario(if url is dynamic, it needs to be replaced by the complete URL)
    }

    const clickHandler = createFunction(clickHandlerString, ['page', 'button', 'authData', 'supportingData'], () => { alert('No action defined'); });

    await clickHandler(this.page, this, { authorizationToken, userId, state, user }, data);

  }

  async getAuthorization(data) {

    const state = data;
    window.location.href = `${this.getAuthenticatorURL()}?origin=${window.location.origin}&redirect-path=${window.location.pathname}&observerId=${this.page.userObserver.getObserverId()}&state=${JSON.stringify(state)}`;

  }

  async tryRedirectToURL(data) {

    const token = this.page.getAuthorizationToken();

    const user = await this.page.tryInitializeUser();

    // Always read from cookie. It is the source of truth for the user's authorization.
    if (user !== null) {
      sendBeacon('/batman-begins', { type: 'authentication/internal-redirect', data });
      try {

        await this.redirectToURL(this.page.getAuthorizationToken(), user, {}, { registered: false });

      } catch (exception) {

        sendBeacon('/batman-begins', {
          type: 'authentication/internal-redirect-failed',
          data: {
            ...data,
            token,
            exception
          }
        });

      }
      return;
    }
    sendBeacon('/batman-begins', { type: 'authentication/external-redirect', data });
    try {

      this.getAuthorization(data);

    } catch (exception) {

      sendBeacon('/batman-begins', {
        type: 'authentication/external-redirect-failed',
        data: {
          ...data,
          exception
        }
      });

    }

  }

  getAuthenticatorURL() {

    const host = this.page.startup.address.host;

    if (host.indexOf('localhost') !== -1) {
      return 'http://auth.local.com:3002';
    } else if (host.indexOf('development') !== -1) {
      return 'https://auth.development.venturz.co';
    } else {
      return 'https://auth.venturz.co';
    }

  }

  setWorking() {

    setChildren(this.button, [this.spinner]);
    this.disabled = true;

  }

  setIdle() {

    setChildren(this.button, this.buttonContents);
    this.disabled = false;

  }

  get state() {

    return {
      authButtonId: this.configuration.id,
      uuid: generateUUID(),
      observerId: this.page.userObserver.getObserverId()
    };

  }

  async handleButtonClick(event) {

    event.preventDefault();

    if (this.disabled === true) {
      return;
    }

    const data = this.state;

    sendBeacon('/batman-begins', { type: 'authentication/started', data });

    this.setWorking();

    await this.tryRedirectToURL(data);

    this.setIdle();

    return false;

  }

}