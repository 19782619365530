export function applyTraits(Class, Traits) {

  for (let Trait of Traits) {

    const traitMethods = Object.getOwnPropertyNames(Trait.prototype);

    for (let method of traitMethods) {

      if (method === 'constructor') {
        continue;
      }

      Class.prototype[method] = Trait.prototype[method];

    }

  }

  return Class;

}

export function createFunction(functionString, parameters, defaultFunction) {

  try {

    const func = eval(`var f = ${functionString};f`);

    return func;

  } catch (exception) {

    return defaultFunction;

  }

}