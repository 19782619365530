export function isMobile() {
  const toMatch = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i
  ];

  return toMatch.some((toMatchItem) => {
    return navigator.userAgent.match(toMatchItem);
  });
}

function _generateUUID() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    const r = (Math.random() * 16) | 0;
    const v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export function generateUUID() {

  if (window.crypto) {
    return window.crypto.randomUUID();
  } else {
    return _generateUUID();
  }

}

export function sendBeacon(url, data) {

  const headers = {
    type: 'application/json',
  };
  const blob = new Blob([JSON.stringify(data)], headers);

  window.navigator.sendBeacon('/batman-begins', blob);

}